import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const MobileAppDevelopment = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-8 lg:mt-20">
          <h1 className="text-5xl md:text-6xl font-extrabold text-indigo-900 mb-6">
            Tailored Mobile App Solutions
          </h1>
          <p className="mt-4 text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
            Transforming your ideas into impactful mobile applications that
            enhance user engagement and drive your business growth. Join us on a
            journey to digital excellence.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-12 bg-gradient-to-r from-indigo-600 to-blue-500 text-white rounded-lg shadow-xl mb-20">
          <h2 className="text-4xl font-semibold mb-4">
            Propel Your Business with Our Expertise
          </h2>
          <p className="text-md max-w-2xl mx-auto mb-8">
            Partner with our team to create bespoke mobile solutions tailored to
            resonate with your audience.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-500 font-semibold py-4 px-10 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-100"
          >
            Let's Connect
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20">
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Custom Solutions
            </h3>
            <p className="text-gray-600">
              Our mobile apps are tailored to your unique business needs,
              ensuring impactful and effective solutions.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Intuitive UX/UI
            </h3>
            <p className="text-gray-600">
              We focus on crafting engaging user experiences with intuitive
              designs that make apps enjoyable to use.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Robust Performance
            </h3>
            <p className="text-gray-600">
              Your app will be optimized for speed and efficiency, delivering
              smooth performance even under heavy loads.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl font-semibold text-indigo-900 mb-8">
            Our Proven Development Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                1. Discovery
              </h3>
              <p className="text-gray-600">
                We start by understanding your goals and aspirations to create a
                tailored project roadmap.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                2. Design
              </h3>
              <p className="text-gray-600">
                Our design team crafts wireframes and interactive prototypes,
                ensuring your app’s functionality is well-visualized.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                3. Development
              </h3>
              <p className="text-gray-600">
                Using the latest technologies, our developers build scalable and
                maintainable mobile applications.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                4. Launch
              </h3>
              <p className="text-gray-600">
                We conduct thorough testing and assist with deployment to ensure
                your app reaches your audience effectively.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl font-semibold text-indigo-900 mb-8">
            Client Testimonials
          </h2>
          <div className="space-y-4 max-w-3xl mx-auto">
            <blockquote className="text-md text-gray-600 italic border-l-4 border-indigo-500 pl-4">
              "The team at Omniserveinfotech exceeded our expectations with an
              amazing app!"
            </blockquote>
            <blockquote className="text-md text-gray-600 italic border-l-4 border-indigo-500 pl-4">
              "Exceptional service and a seamless process. Highly recommend for
              app development!"
            </blockquote>
            <blockquote className="text-md text-gray-600 italic border-l-4 border-indigo-500 pl-4">
              "Our partnership with Omniserveinfotech transformed our vision
              into reality!"
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-12 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-md mb-20">
          <h2 className="text-4xl font-bold mb-4">
            Let's Create the Future Together!
          </h2>
          <p className="text-md max-w-2xl mx-auto mb-8">
            Are you ready to develop an app that makes a significant impact?
            Contact us today to discuss your project and get started!
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-500 font-semibold py-4 px-10 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-100"
          >
            Reach Out
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileAppDevelopment;