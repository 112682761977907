import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import crmSoftwareImg from "../images/home-slider/CRM-Software.webp";
import dialerSoftwareImg from "../images/home-slider/Dialer-Software.webp";
import digitalMarketingImg from "../images/home-slider/Web-Development.webp";
import webDevImg from "../images/home-slider/Digital-Marketing.webp";
import { FaArrowRight } from "react-icons/fa";

const NavBar = lazy(() => import("../components/Navbar/NavBar"));
const ScrollIndicator = lazy(() => import("../components/hero/ScrollIndicator"));

const HeroSlide = ({ image, title, subtitle, description, linkPath }) => (
  <div className="relative h-full w-full overflow-hidden">
    <img
      src={image}
      alt={title}
      className="w-full h-full object-cover transform transition-transform duration-1000 scale-110 hover:scale-100 brightness-75"
      loading="lazy"
    />
    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-70"></div>
    <div className="absolute inset-0 flex flex-col justify-center items-center text-white px-4 sm:px-8 md:px-12 animate-fadeIn">
      <span className="text-lg sm:text-xl md:text-2xl mb-4 tracking-widest font-semibold uppercase text-center">
        {subtitle}
      </span>
      <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold mb-6 tracking-wide leading-snug text-center"
        style={{
          color: "#78D416",
          textShadow: "0 4px 10px rgba(0, 0, 0, 0.9)",
        }}>
        {title}
      </h3>
      <div className="text-sm sm:text-md md:text-lg mb-6 text-white p-4 rounded text-center opacity-100 transition-all duration-300 hover:bg-opacity-100 bg-black bg-opacity-30">
        {description.map((text, index) => (
          <p key={index}
            className="mb-3 tracking-tight leading-relaxed"
            style={{
              color: "rgba(255, 255, 255, 0.9)",
              textShadow: "0 3px 8px rgba(255, 255, 255, 0.8), 0 5px 15px rgba(0, 0, 0, 0.7)",
            }}>
            {text}
          </p>
        ))}
      </div>
      <Link to={linkPath}
        className="inline-flex items-center px-4 sm:px-6 py-2 text-sm font-semibold text-[#78D416] bg-transparent border-2 border-[#78D416] rounded-full shadow-lg transition-all duration-300 hover:bg-[#78D416] hover:text-white hover:border-transparent">
        <span>Learn More</span>
        <FaArrowRight className="ml-2" />
      </Link>
    </div>
  </div>
);

const HeroSwiper = ({ slides }) => (
  <Swiper
    modules={[Navigation, Pagination, Autoplay]}
    spaceBetween={0}
    slidesPerView={1}
    pagination={{ clickable: true }}
    navigation={true}
    autoplay={{
      delay: 4000,
      disableOnInteraction: false,
    }}
    loop={true}
    speed={800}
    className="h-full"
  >
    {slides.map((slide, index) => (
      <SwiperSlide key={index}>
        <HeroSlide {...slide} />
      </SwiperSlide>
    ))}
  </Swiper>
);


const Hero = () => {
  const slides = [
    {
      image: crmSoftwareImg,
      title: "CRM Software",
      subtitle: "Optimize Customer Relations",
      description: [
        "Empower your sales team with our cutting-edge CRM solutions designed for growth.",
        "Our CRM software helps streamline processes, improve customer relationships, and drive sales.",
      ],
      linkPath: "/products/CRM-software",
    },
    {
      image: dialerSoftwareImg,
      title: "Dialer Software",
      subtitle: "Enhance Your Call Efficiency",
      description: [
        "Streamline your outbound calls and improve productivity with our efficient dialer solutions.",
        "Our software automates your dialing process, ensuring faster connections with clients.",
      ],
      linkPath: "/products/auto-dialer-software",
    },
    {
      image: digitalMarketingImg,
      title: "Web Design & Development",
      subtitle: "Innovative Web Solutions",
      description: [
        "Creating seamless and visually stunning websites tailored to your business needs.",
        "Our expert team ensures your site is user-friendly and optimized for conversions.",
      ],
      linkPath: "/services/web-development",
    },
    {
      image: webDevImg,
      title: "Digital Marketing",
      subtitle: "Tailored Solutions for Your Business",
      description: [
        "Transforming clicks into loyal customers through innovative digital strategies.",
        "Our tailored solutions ensure that your brand stands out and connects with the right audience.",
      ],
      linkPath: "/services/digital-marketing",
    },
  ];

  return (
    <div className="hero relative w-full h-[90vh] md:h-[90vh] sm:h-[70vh] overflow-hidden bg-gradient-to-r from-blue-500 to-indigo-600 font-sans">
      <Suspense fallback={<div>Loading...</div>}>
        <NavBar className="bg-white shadow-lg z-10" />
        <ScrollIndicator />
        <HeroSwiper slides={slides} />
      </Suspense>
      <div className="content-section p-4 sm:p-5 md:p-10 mt-8 rounded-lg bg-opacity-70 backdrop-blur-lg shadow-2xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {slides.map((slide, index) => (
            <div key={index}
              className="service-card bg-white/10 p-4 sm:p-6 rounded-xl shadow-xl transform transition-transform duration-300 hover:scale-105 hover:bg-white/20">
              <h3 className="text-md sm:text-lg font-semibold mb-2 text-center"
                style={{
                  color: "#78D416",
                  textShadow: "0 1px 3px rgba(0, 0, 0, 0.5)",
                }}>
                {slide.title}
              </h3>
              <p className="text-gray-300 mb-2 text-sm sm:text-md">{slide.subtitle}</p>
              <Link to={slide.linkPath}
                className="inline-flex items-center px-3 py-1 text-xs sm:text-sm font-semibold text-[#78D416] border border-[#78D416] rounded-full shadow-md transition-all duration-300 hover:bg-[#78D416] hover:text-white transform hover:scale-105">
                <span>Learn More</span>
                <FaArrowRight className="ml-1" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
