import React, { useState, useEffect } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import Notiflix from "notiflix";
import axios from "axios";

const Contact = () => {
  useDocTitle("Omniserve Infotech - Contact Us");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [product, setProduct] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const clearErrors = () => {
    setErrors({});
  };

  const clearInput = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setProduct("");
  };

  useEffect(() => {
    if (document.cookie.indexOf("humans_21909=1") === -1) {
      document.cookie = "humans_21909=1";
      document.location.reload(true);
    }
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    clearErrors();

    const nameRegex = /^[A-Za-z]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const newErrors = {};
    if (!firstName) {
      newErrors.first_name = "First Name is required.";
    } else if (!nameRegex.test(firstName)) {
      newErrors.first_name = "First Name should contain only letters.";
    }

    if (!lastName) {
      newErrors.last_name = "Last Name is required.";
    } else if (!nameRegex.test(lastName)) {
      newErrors.last_name = "Last Name should contain only letters.";
    }

    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!phone) {
      newErrors.phone_number = "Phone number is required.";
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone_number = "Phone number must be exactly 10 digits.";
    }

    if (!product) newErrors.product = "Product selection is required.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    const fData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      message: message,
      product: product,
    };

    setLoading(true);

    axios
      .post("/backend/contact.php", fData, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
        params: {
          t: new Date().getTime(),
        },
      })
      .then((response) => {
        clearInput();
        Notiflix.Report.success("Success", response.data.message, "Okay");
      })
      .catch((error) => {
        setLoading(false);

        if (error.response) {
          const { status, data } = error.response;

          if (status === 409) {
            Notiflix.Report.failure(
              "Conflict",
              data.message || "Data already exists.",
              "Okay"
            );
          } else if (status === 500) {
            Notiflix.Report.failure("An error occurred", data.message, "Okay");
          }
        } else {
          Notiflix.Report.failure(
            "Error",
            error.message || "Unable to connect to the server.",
            "Okay"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-gradient-to-b from-white via-blue-50 to-blue-100 py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form onSubmit={sendEmail}>
            <div className="w-full bg-white bg-opacity-90 p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-lg">
              <div className="flex justify-between items-center mb-6">
                <h1
                  className="font-bold text-blue-700 uppercase text-2xl"
                  style={{ color: "#3E7DBA" }}
                >
                  Send Us a Message
                </h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="text-blue-500 font-semibold"
                    style={{ color: "#3E7DBA" }}
                  >
                    First Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="first_name"
                    name="first_name"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="text"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors.first_name && (
                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="text-blue-500 font-semibold"
                    style={{ color: "#3E7DBA" }}
                  >
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="last_name"
                    name="last_name"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="text"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors.last_name && (
                    <p className="text-red-500 text-sm">{errors.last_name}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-blue-500 font-semibold"
                    style={{ color: "#3E7DBA" }}
                  >
                    Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="text-blue-500 font-semibold"
                    style={{ color: "#3E7DBA" }}
                  >
                    Phone<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="phone_number"
                    name="phone_number"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="tel"
                    placeholder="Enter your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors.phone_number && (
                    <p className="text-red-500 text-sm">
                      {errors.phone_number}
                    </p>
                  )}
                </div>
              </div>
              <div className="my-4">
                <label
                  htmlFor="message"
                  className="text-blue-500 font-semibold"
                  style={{ color: "#3E7DBA" }}
                >
                  Remarks <span className="text-gray-500">(Optional)</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Type your message here"
                  className="w-full h-20 bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
              <div className="my-4">
                <label
                  htmlFor="product"
                  className="text-blue-500 font-semibold"
                  style={{ color: "#3E7DBA" }}
                >
                  Select Your Product <span className="text-red-500">*</span>
                </label>
                <select
                  id="product"
                  name="product"
                  className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  onKeyUp={clearErrors}
                >
                  <option value="" disabled>
                    Product Category
                  </option>
                  <option value="Web Design & Development">
                    Web Design & Development
                  </option>
                  <option value="Mobile App Development">
                    Mobile App Development
                  </option>
                  <option value="Domain & Hosting">Domain & Hosting</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="UI/UX Design">UI/UX Design</option>
                </select>
                {errors.product && (
                  <p className="text-red-500 text-sm">{errors.product}</p>
                )}
              </div>
              <div className="flex justify-start mt-3">
                <button
                  id="submitBtn"
                  type="submit"
                  className={`text-white inline-flex items-center justify-center w-full px-4 py-2 md:px-6 md:py-3 mt-4 lg:mt-6 text-sm md:text-base lg:text-base transition duration-300 shadow-lg rounded-full sm:w-auto ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  style={{
                    backgroundColor: "rgb(62, 125, 186)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgb(48, 97, 142)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "rgb(62, 125, 186)";
                  }}
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>
          </form>
          <div
            className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto text-white rounded-2xl shadow-lg"
            style={{ backgroundColor: "#3e7dba" }}
          >
            <div className="flex flex-col">
              <div className="flex items-start my-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-10 mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5h-3M12 9v6m0 0h3.75m-3.75 0H10.5m9 0A9 9 0 116.75 8.25m12.25 3.75h.008v.008h-.008v-.008z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.32 4.11a1.5 1.5 0 00-1.61-.25 1.5 1.5 0 00-.62 1.97c.17.33 1.16 2.27 3.09 4.25 1.93 1.98 3.91 2.9 4.25 3.09a1.5 1.5 0 001.97-.62 1.5 1.5 0 00-.25-1.61l-1.5-1.5a1.5 1.5 0 00-1.61-.25A10.01 10.01 0 016.32 4.11z"
                  />
                </svg>
                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold">Contact Details</h1>
                  <p className="my-1">
                    <span className="font-semibold">Phone:</span> +91 9990973790
                  </p>
                  <p className="my-1">
                    <span className="font-semibold">Email:</span>{" "}
                    info@omniserveinfotech.com
                  </p>
                </div>
              </div>
              <div className="flex items-start my-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-16 h-6 mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2.25c-3.75 0-6.75 3-6.75 6.75 0 5.25 6.75 12 6.75 12s6.75-6.75 6.75-12C18.75 5.25 15.75 2.25 12 2.25zM12 10.5c-1.125 0-2.25.75-2.25 2.25S10.875 15 12 15s2.25-.75 2.25-2.25S13.125 10.5 12 10.5z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12h4.5M21 12v-4.5M21 12v4.5"
                  />
                </svg>

                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold">Visit Us</h1>
                  <p className="my-1">
                    <span className="font-semibold">Address:</span> T-1/103, MCC
                    Signature Homes, Raj Nagar Extension, Ghaziabad-201017,
                    Uttar Pradesh, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;