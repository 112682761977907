import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const DigitalMarketing = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-8 lg:mt-20">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Elevate Your Brand with Digital Marketing
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Our digital marketing services help your business reach more
            customers and grow online through strategic campaigns.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Transform Your Online Presence!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            Unlock the potential of your brand with customized digital marketing
            strategies that deliver results.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-50"
          >
            Get Started Today
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Social Media Marketing
            </h3>
            <p className="text-gray-600">
              We create engaging social media campaigns to enhance your brand
              visibility and audience engagement.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Content Marketing
            </h3>
            <p className="text-gray-600">
              Our team crafts compelling content that attracts and retains your
              target audience.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Pay-Per-Click Advertising
            </h3>
            <p className="text-gray-600">
              We manage targeted PPC campaigns that drive immediate traffic and
              conversions to your website.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our Digital Marketing Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                1. Analysis
              </h3>
              <p className="text-gray-600">
                We conduct a comprehensive analysis of your current digital
                presence and competitors.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                2. Strategy
              </h3>
              <p className="text-gray-600">
                Our team develops a tailored digital marketing strategy to meet
                your business objectives.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                3. Implementation
              </h3>
              <p className="text-gray-600">
                We execute the marketing plan with precision, focusing on both
                online and offline strategies.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                4. Reporting
              </h3>
              <p className="text-gray-600">
                We provide regular performance reports and make adjustments as
                needed to optimize results.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Our engagement on social media skyrocketed after working with
              their team!"
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "They delivered exceptional results, transforming our brand's
              online presence."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Thanks to their digital marketing expertise, we have seen
              tremendous growth in our sales."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-blue-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Let’s Elevate Your Brand Together!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            Our team is ready to enhance your digital marketing efforts. Contact
            us to discuss your project needs.
          </p>
          <a
            href="/contact"
            className="inline-block bg-blue-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DigitalMarketing;