import React, { useState, useEffect } from "react";
import NavLinks from "../Navbar/NavLinks";
import { HashLink } from "react-router-hash-link";
import navbar from "../../images/navbar/logo.webp";

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      const scrollY = window.pageYOffset;
      setTop(scrollY < 10);

      if (scrollY >= 100 && showDetails) {
        setIsClosing(true);
        setTimeout(() => {
          setShowDetails(false);
        }, 300);
      } else if (scrollY < 100 && !showDetails) {
        setShowDetails(true);
        setIsClosing(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [showDetails]);

  return (
    <nav
      className={`navbar-fixed transition-all duration-300 ease-in-out mb-16 ${
        !top ? "bg-white shadow-lg" : "bg-white"
      }`}
    >
      {/* Details column */}
      {showDetails && (
        <div
          className={`hidden lg:flex justify-between items-center p-2 px-4 transition-opacity duration-300 ease-in-out ${
            isClosing ? "opacity-0" : "opacity-100"
          }`}
          style={{ background: "#3e7dba" }} // Updated background color
        >
          <p className="text-white font-medium text-base">
            <b>Contact Us:</b> +91 9990973790,&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <b>E-Mail:</b> info@omniserveinfotech.com
          </p>
          <p className="text-white text-base">
            <ul className="flex justify-start sm:justify-start space-x-4">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61567109308065&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-blue-600 rounded-full w-8 h-8 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/omniserveinfotech/profilecard/?igsh=NnU0cmd6bXJyY2dl"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-pink-500 rounded-full w-8 h-8 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/omniserve-infotech-173562332"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-blue-700 rounded-full w-8 h-8 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </p>
        </div>
      )}

      <div className="flex justify-between items-center py-4 px-6 md:px-8 lg:px-12">
        <div className="flex items-center">
          <HashLink smooth to="/">
            <img
              src={navbar}
              alt="client"
              className="h-12 md:h-16 lg:h-11 transition-transform duration-200 ease-in-out transform hover:scale-105"
            />
          </HashLink>
        </div>
        <div className="group flex flex-col items-center">
          <button
            className="p-2 rounded-lg lg:hidden text-blue-900 transition-transform duration-200 ease-in-out transform hover:scale-110"
            onClick={handleClick}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
          <div className="hidden lg:flex space-x-8 p-4 md:p-5 lg:p-3">
            <NavLinks />
          </div>

          <div
            className={`fixed transition-transform duration-300 ease-in-out flex flex-col justify-center left-0 w-full h-auto bg-white lg:hidden top-16 p-6 rounded-md ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col space-y-4">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
