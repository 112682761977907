import React, { useEffect } from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import autoDialerImg from "../../images/hero-bg.webp";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaUserCheck, FaCog, FaChartLine } from "react-icons/fa";

const AutoDialerSoftware = () => {
  useEffect(() => {
    document.title = "Omniserve Infotech - Auto Dialer Software";
  }, []);

  return (
    <>
      <Helmet>
        <title>Omniserve Infotech - Auto Dialer Software</title>
      </Helmet>
      <NavBar />

      {/* Hero Section */}
      <div className="relative w-full h-screen overflow-hidden bg-gradient-to-br from-blue-600 to-indigo-800">
        <img
          src={autoDialerImg}
          alt="Auto Dialer Software"
          className="absolute inset-0 object-cover w-full h-full opacity-25"
          loading="lazy"
        />
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="text-center px-4 sm:px-8 lg:px-16">
            <h1 className="text-5xl md:text-7xl font-extrabold mb-4 animate-fade-in-down">
              Auto Dialer Software
            </h1>
            <p className="text-base md:text-xl mb-8 max-w-2xl mx-auto animate-fade-in-up text-gray-200">
              Revolutionizing communication with automated dialing solutions
              that enhance productivity and efficiency.
            </p>
            <Link to="/contact">
              <button className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-700 hover:to-indigo-700 text-white font-bold py-3 px-8 rounded-full shadow-lg transition duration-300 animate-fade-in-up delay-150 transform hover:scale-105">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto p-6 mt-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Why Choose Our Auto Dialer Software?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <FeatureItem
            icon={<FaPhoneAlt className="text-indigo-600 h-12 w-12" />}
            title="Increased Call Efficiency"
            description="Automatically dial multiple numbers to save time."
          />
          <FeatureItem
            icon={<FaUserCheck className="text-indigo-600 h-12 w-12" />}
            title="Improved Lead Management"
            description="Organize and prioritize your leads effortlessly."
          />
          <FeatureItem
            icon={<FaCog className="text-indigo-600 h-12 w-12" />}
            title="User-Friendly Interface"
            description="Intuitive design for easy navigation and operation."
          />
          <FeatureItem
            icon={<FaChartLine className="text-indigo-600 h-12 w-12" />}
            title="Performance Analytics"
            description="Track call metrics to enhance strategy and results."
          />
        </div>
      </div>

      {/* Product Benefits Section */}
      <div className="container mx-auto p-6 mt-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Key Benefits of Our Auto Dialer Software
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <FeatureItem
            icon={<FaCog className="text-indigo-600 h-12 w-12" />}
            title="Cost Efficiency"
            description="Reduce manual efforts, save time, and lower operational costs."
          />
          <FeatureItem
            icon={<FaUserCheck className="text-indigo-600 h-12 w-12" />}
            title="Customizable Dialing"
            description="Personalize settings for specific needs and better performance."
          />
          <FeatureItem
            icon={<FaChartLine className="text-indigo-600 h-12 w-12" />}
            title="Seamless Integration"
            description="Easily integrates with your CRM and contact lists."
          />
        </div>
      </div>

      {/* FAQs Section */}
      <div className="container mx-auto p-6 mt-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          <FaqItem
            question="Is it compatible with all CRMs?"
            answer="Yes, our auto dialer software is compatible with most major CRM systems."
          />
          <FaqItem
            question="How secure is my data?"
            answer="We prioritize data security with industry-standard encryption and privacy protocols."
          />
          <FaqItem
            question="Can I customize the dialing options?"
            answer="Absolutely! You can configure the dialer according to your specific needs."
          />
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gray-100 py-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-6">
          What Our Clients Say
        </h2>

        <div className="flex flex-wrap justify-center space-x-4">
          <TestimonialItem
            name="Rohit Sharma"
            color="blue-500"
            feedback="The Auto Dialer Software has significantly boosted our call productivity!"
          />
          <TestimonialItem
            name="Priya Gupta"
            color="green-500"
            feedback="Incredible tool for managing calls efficiently. Highly recommended!"
          />
          <TestimonialItem
            name="Amit Verma"
            color="orange-500"
            feedback="The support team is exceptional, and the software is very effective!"
          />
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-gradient-to-r from-blue-700 to-indigo-700 text-white text-center py-12 mt-12">
        <h2 className="text-3xl md:text-4xl font-bold mb-6">
          Ready to Transform Your Calling Experience?
        </h2>
        <Link to="/contact">
          <button className="bg-white text-blue-700 font-bold py-3 px-8 rounded-full shadow-lg transition duration-300 hover:bg-gray-100 transform hover:scale-105">
            Get a Free Demo
          </button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

const FeatureItem = ({ icon, title, description }) => (
  <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
    <div className="mb-4">{icon}</div>
    <h3 className="font-semibold text-xl mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const StepItem = ({ stepNumber, title, description }) => (
  <div className="bg-white shadow-lg rounded-lg p-6 m-4 w-64 text-center transition-transform transform hover:scale-105 duration-300">
    <div className="text-blue-600 text-4xl font-bold mb-4">{stepNumber}</div>
    <h3 className="font-semibold text-xl mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const FaqItem = ({ question, answer }) => (
  <div className="bg-white shadow rounded-lg p-4">
    <h3 className="font-semibold">{question}</h3>
    <p className="text-gray-600">{answer}</p>
  </div>
);

const TestimonialItem = ({ name, color, feedback }) => (
  <div
    className={`max-w-xs bg-white shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105 duration-300 border-l-4 border-${color}`}
  >
    <div className="flex items-center mb-4">
      <div
        className={`bg-${color} text-white rounded-full w-12 h-12 flex items-center justify-center mr-4`}
      >
        <FaUserCheck className="h-6 w-6" />
      </div>
      <h3 className="font-semibold text-lg">{name}</h3>
    </div>
    <p className="text-gray-600 italic">{feedback}</p>
  </div>
);

export default AutoDialerSoftware;