import React, { lazy, Suspense, useEffect } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import { Helmet } from "react-helmet";
import Loader from "../components/Loader";

const Intro = lazy(() => import("../components/Intro"));
const Services = lazy(() => import("../components/Services"));
const Portfolio = lazy(() => import("../components/Portfolio"));
const GetInTouch = lazy(() => import("../components/GetInTouch"));
const WhatsAppButton = lazy(() => import("../components/WhatsAppButton"));
const WhyChooseUs = lazy(() => import("../components/WhyChooseUs"));

const Home = () => {
  // Scroll to top on every refresh
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>OmniserveInfotech - Website Development, Financial Solutions,...</title>
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Hero />
        <Intro />
        <Services />
        <WhyChooseUs />
        <Portfolio />
        <GetInTouch />
        <Footer />
        <WhatsAppButton />
      </Suspense>
    </>
  );
};

export default Home;