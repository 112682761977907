import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Service = () => {
  const services = [
    {
      title: "Web Development",
      description:
        "Building responsive and high-quality websites tailored to your needs.",
      icon: "🌐",
      link: "/services/web-development",
    },
    {
      title: "Mobile App Development",
      description:
        "Creating user-friendly mobile applications for both iOS and Android platforms.",
      icon: "📱",
      link: "/services/mobile-app-development",
    },
    {
      title: "UI/UX Design",
      description:
        "Designing intuitive and visually appealing user interfaces and experiences.",
      icon: "🎨",
      link: "/services/ui-ux-design",
    },
    {
      title: "SEO Optimization",
      description:
        "Improving your website’s visibility on search engines through proven strategies.",
      icon: "🔍",
      link: "/services/seo-optimization",
    },
    {
      title: "Digital Marketing",
      description: "Promoting your brand effectively across digital platforms.",
      icon: "📢",
      link: "/services/digital-marketing",
    },
    {
      title: "Email Marketing",
      description:
        "Reaching your customers directly through targeted email campaigns.",
      icon: "📧",
      link: "/services/email-marketing",
    },
    {
      title: "Custom Software Development",
      description:
        "Empowering your business with customized software that adapts to your vision and scales with your growth.",
      icon: "🛠️",
      link: "/services/custom-software-development",
    },
    {
      title: "Web Hosting Services",
      description:
        "Empowering your digital presence with lightning-fast, secure, and affordable web hosting.",
      icon: "🌐",
      link: "/services/web-hosting",
    },
  ];

  return (
    <>
      <Helmet>
        <title>OmniserveInfotech - Services</title>
      </Helmet>
      <NavBar />
      <div
        className="m-auto mx-4 mt-16 lg:mt-24 p-2 md:p-12 h-auto"
        data-aos="zoom-in"
      >
        <div className="flex flex-col py-8 text-center">
          <h1
            className="text-3xl font-bold mb-4 text-blue-600"
            style={{ color: "rgb(62,125,186)" }}
          >
            Our Services
          </h1>
          <p className="text-lg mb-8 text-gray-700 text-justify lg:text-center">
            We offer personalized, high-quality solutions designed specifically
            for your unique business needs. Our dedicated team takes the time to
            understand your goals and challenges, ensuring that we deliver
            tailored services that not only meet but exceed your expectations.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center transition-transform transform hover:-translate-y-1 hover:shadow-xl duration-300"
            >
              <Link
                to={service.link || "#"}
                className="w-full h-full"
                aria-label={service.title}
              >
                <div className="text-6xl mb-4 text-blue-500">
                  {service.icon || "🛠️"}
                </div>
                <h2
                  className="text-xl lg:text-2xl font-semibold mb-2"
                  style={{ color: "#3E7DBA" }}
                >
                  {service.title}
                </h2>
                <p className="text-gray-600">{service.description}</p>
                {/* Explore Button */}
                <Link
                  to={service.link}
                  className="mt-4 inline-block w-4/5 px-4 py-2 text-sm font-semibold text-white bg-[#78D416] rounded transition duration-300 hover:bg-transparent hover:text-[#78D416] border-2 border-transparent hover:border-[#78D416]"
                  aria-label={`Explore ${service.title}`}
                >
                  Explore
                </Link>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Service;