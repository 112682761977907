import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPhoneVolume, FaNetworkWired, FaUserFriends, FaRobot } from "react-icons/fa";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const ProductPage = () => {
  const products = [
    {
      id: 1,
      title: "Auto Dialer Software",
      description:
        "Omniserve Infotech auto dialer software helps automatically dial a large number of leads and saves significant time. It enables you to connect a customer to a live agent. This leads to high productivity and efficiency.",
      icon: <FaPhoneVolume size={80} className="text-blue-600 mx-auto mb-4" />,
      link: "/products/auto-dialer-software",
    },
    {
      id: 2,
      title: "Gateway devices",
      description:
        "Omniserve Infotech GSM gateways provide a way to route traffic from a GSM network to another type of network. GSM gateways are often used to route traffic from wireless GSM networks to wired networks. By routing traffic through a GSM gateway,",
      icon: <FaNetworkWired size={80} className="text-blue-600 mx-auto mb-4" />,
      link: "/products/gateway-device",
    },
    {
      id: 3,
      title: "CRM Software",
      description:
        "Omniserve Infotech CRM software helps you manage customer interaction and relationships. The software can track interactions with customers, such as sales calls, customer service interactions, and marketing emails.",
      icon: <FaUserFriends size={80} className="text-blue-600 mx-auto mb-4" />,
      link: "/products/CRM-software",
    },
    {
      id: 4,
      title: "IVR System",
      description:
        "Omniserve Infotech Interactive Voice Response, or IVR, is an automated telephone system technology that enables callers to receive or provide information or make requests using voice or menu inputs, without speaking to live agents.",
      icon: <FaRobot size={80} className="text-blue-600 mx-auto mb-4" />,
      link: "/products/IVR-system",
    },
  ];

  useEffect(() => {
    document.title = "Omniserve Infotech - Products";
  }, []);

  return (
    <>
      <Helmet>
        <title>OmniserveInfotech - Products</title>
      </Helmet>
      <NavBar />
      <div
        className="m-auto overflow-hidden p-4 md:p-12 h-auto mt-16 lg:mt-24"
        data-aos="zoom-in"
      >
        <div id="hero" className="flex flex-col py-8 text-center">
          <h1
            className="text-3xl font-bold mb-4 text-blue-600"
            style={{ color: "rgb(62,125,186)" }}
          >
            Our Products
          </h1>
          <p className="text-lg mb-8 text-gray-700 text-justify lg:text-center">
            "Empowering businesses with cutting-edge digital solutions that
            drive growth, enhance efficiency, and elevate customer experiences."
          </p>
        </div>
        {/* Product Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <Link
              to={product.link}
              key={product.id}
              className="bg-white shadow-2xl rounded-lg overflow-hidden flex flex-col transition-transform transform hover:scale-105 hover:shadow-3xl duration-300"
              aria-labelledby={`product-title-${product.id}`}
            >
              <div className="flex items-center justify-center h-48">
                {product.icon}
              </div>
              <div className="flex-grow p-6 text-center">
                <h2
                  id={`product-title-${product.id}`}
                  className="text-xl lg:text-2xl font-semibold mb-2"
                  style={{ color: "#3E7DBA" }}
                >
                  {product.title}
                </h2>
                <p className="text-gray-600 mb-4">{product.description}</p>
              </div>
              <button
                className="text-white font-bold py-2 px-4 rounded transition duration-300 mb-4 mx-6 mt-2 shadow-lg hover:scale-105"
                style={{
                  backgroundColor: "rgb(62, 125, 186)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "rgb(48, 97, 142)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "rgb(62, 125, 186)";
                }}
              >
                Explore
              </button>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;