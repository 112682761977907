import React, { useEffect } from "react";
import AOS from "aos";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing all pages
import Home from "./pages/Home";
import AboutUs from "./pages/about-us";
import Service from "./pages/services";
import ProductPage from "./pages/products";
import Contact from "./pages/Contact";
import TermsOfServices from "./pages/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DemoProduct from "./pages/DemoProduct";
// Service Pages
import WebDevelopment from "./pages/our-services/WebDevelopment";
import MobileAppDevelopment from "./pages/our-services/Mobile-App-Development";
import UiuxDesign from "./pages/our-services/UiuxDesign";
import SeoOptimization from "./pages/our-services/Seo-Optimization";
import DigitalMarketing from "./pages/our-services/DigitalMarketing";
import EmailMarketing from "./pages/our-services/EmailMarketing";
import CustomSoftwareDevelopment from "./pages/our-services/CustomSoftwareDevelopment";
import WebHosting from "./pages/our-services/WebHosting";
// Product Pages
import GatewayDevicesPage from "./pages/product/GatewayDevicesPage";
import AutoDialerSoftware from "./pages/product/AutoDialerSoftware";
import CRMSoftware from "./pages/product/CrmSoftware";
import IVRSystem from "./pages/product/IvrSystem";

// Custom Hooks and ScrollToTop
import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  // Initialize AOS animations
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  // Set document title
  useDocTitle(
    "OmniserveInfotech - Website Development, Financial Solutions, Healthcare"
  );

  return (
    <Router>
      {/* Wrapping Routes with ScrollToTop */}
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Service />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/terms-of-service" element={<TermsOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Add service routes */}
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/services/mobile-app-development" element={<MobileAppDevelopment />} />
          <Route path="/services/ui-ux-design" element={<UiuxDesign />} />
          <Route path="/services/seo-optimization" element={<SeoOptimization />} />
          <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/email-marketing" element={<EmailMarketing />} />
          <Route path="/services/custom-software-development" element={<CustomSoftwareDevelopment />} />
          <Route path="/services/web-hosting" element={<WebHosting />} />
          {/* Add product routes */}
          <Route path="/products/gateway-device" element={<GatewayDevicesPage />} />
          <Route path="/products/auto-dialer-software" element={<AutoDialerSoftware />} />
          <Route path="/products/CRM-software" element={<CRMSoftware />} />
          <Route path="/products/IVR-system" element={<IVRSystem />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;