/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "/backend/subscribe.php",
        `email=${encodeURIComponent(email)}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.success) {
        if (response.data.message === "You already subscribed.") {
          setMessage("You have already subscribed.");
          setMessageType("success");
        } else {
          setMessage("Thank you for subscribing! Please check your email.");
          setMessageType("success");
        }
        setEmail("");
      } else {
        setMessage(response.data.message);
        setMessageType("error");
      }
    } catch (error) {
      setMessage("There was an error. Please try again later.");
      setMessageType("error");
    } finally {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        setMessage("");
      }, 8000);
    }
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 border-b border-gray-700 pb-10">
          {/* Company Info */}
          <div className="text-left space-y-4">
            <h3 className="font-extrabold text-2xl mb-4 text-blue-400" style={{color: "#3E7DBA"}}>
              Omniserve Infotech
            </h3>
            <p className="text-sm font-medium leading-relaxed text-justify">
              T-1/103, MCC Signature Homes, Raj Nagar Extension,
              Ghaziabad-201017, Uttar Pradesh, India
            </p>
            <div className="mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13998.823955542886!2d77.41439999679808!3d28.698440320115377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sT-1%2F103%2C%20Signature%20Homes%2C%20New%20Raj%20Nagar%20Extension%2C%20Ghaziabad-201002%2C%20Uttar%20Pradesh%2C%20India!5e0!3m2!1sen!2sin!4v1728371831638!5m2!1sen!2sin"
                width="100%"
                height="200"
                className="border-0 rounded-lg shadow-lg mt-2"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>

          {/* Quick Links */}
          <div className="text-left space-y-4">
            <h6 className="text-blue-400 text-xl font-bold mb-4" style={{color: "#3E7DBA"}}>
              Quick Links
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <HashLink to="/about-us" className="hover:text-blue-300">
                  About
                </HashLink>
              </li>
              <li>
                <HashLink to="/services" className="hover:text-blue-300">
                  Services
                </HashLink>
              </li>
              <li>
                <HashLink to="/contact" className="hover:text-blue-300">
                  Contact Us
                </HashLink>
              </li>
              <li>
                <HashLink to="/privacy-policy" className="hover:text-blue-300">
                  Privacy Policy
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/terms-of-service"
                  className="hover:text-blue-300"
                >
                  Terms of Service
                </HashLink>
              </li>
            </ul>
            <h6 className="text-blue-400 text-xl font-bold mb-4" style={{color: "#3E7DBA"}}>
              Contact Details
            </h6>
            <p className="text-sm mb-4">
              <b>Mobile No:</b> +91 9990973790
            </p>
            <p className="text-sm mb-4">
              <b>E-mail:</b> info@omniserveinfotech.com
            </p>
          </div>

          {/* Services */}
          <div className="text-left space-y-4">
            <h6 className="text-blue-400 text-xl font-bold mb-4" style={{color: "#3E7DBA"}}>
              Products & Services
            </h6>
            <ul className="text-sm space-y-2">
              <li>
                <Link
                  to="/services/web-development"
                  className="hover:text-blue-300"
                >
                  Web Development
                </Link>
              </li>
              <li>
                <Link
                  to="/services/mobile-app-development"
                  className="hover:text-blue-300"
                >
                  Mobile App Development
                </Link>
              </li>
              <li>
                <Link
                  to="/services/web-hosting"
                  className="hover:text-blue-300"
                >
                  Domain & Hosting
                </Link>
              </li>
              <li>
                <Link
                  to="/services/email-marketing"
                  className="hover:text-blue-300"
                >
                  Email Marketing
                </Link>
              </li>
              <li>
                <Link
                  to="/services/digital-marketing"
                  className="hover:text-blue-300"
                >
                  Digital Marketing
                </Link>
              </li>
              <li>
                <Link
                  to="/services/ui-ux-design"
                  className="hover:text-blue-300"
                >
                  UI/UX Design
                </Link>
              </li>
            </ul>
          </div>

          {/* Subscribe & Social */}
          <div className="text-left space-y-4">
            <h6 className="text-blue-400 text-xl font-bold mb-4" style={{color: "#3E7DBA"}}>Follow Us</h6>
            <p className="text-sm mb-4">
              Stay connected with us on social media.
            </p>
            <ul className="flex justify-start sm:justify-start space-x-4 mb-6">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61567109308065&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-blue-900 rounded-full w-10 h-10 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/omniserveinfotech/profilecard/?igsh=NnU0cmd6bXJyY2dl"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-blue-900 rounded-full w-10 h-10 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/omniserve-infotech-173562332"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-blue-900 rounded-full w-10 h-10 flex items-center justify-center hover:scale-110 transition transform"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>

            {/* Subscribe Form */}
            <h6 className="text-blue-400 text-xl font-bold mb-4" style={{color: "#3E7DBA"}}>Subscribe</h6>
            <p className="text-sm mb-2">Get the latest updates.</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 border border-gray-600 rounded mb-2 w-full text-black"
              placeholder="Enter your email"
            />
            <button
              onClick={handleSubscribe}
              className="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded"
            >
              Subscribe
            </button>

            {/* Message Display with Fade-Out Animation */}
            {showMessage && (
              <div
                className={`flex items-center p-2 rounded mb-4 transition-opacity duration-1000 ${
                  messageType === "success" ? "bg-green-500" : "bg-red-500"
                }`}
                style={{ opacity: showMessage ? 1 : 0 }}
              >
                {messageType === "success" ? (
                  <AiOutlineCheckCircle className="text-2xl mr-2" />
                ) : (
                  <AiOutlineCloseCircle className="text-2xl mr-2" />
                )}
                <span>{message}</span>
              </div>
            )}
          </div>
        </div>

        <div className="text-center mt-8">
          <p className="text-sm text-gray-400">
            &copy; {new Date().getFullYear()} Omniserve Infotech. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
