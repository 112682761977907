import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const MobileAppDevelopment = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-8 lg:mt-20">
          <h1 className="text-6xl font-extrabold text-indigo-900 mb-6">
            Innovative Mobile App Solutions
          </h1>
          <p className="mt-4 text-lg text-gray-700 max-w-4xl mx-auto leading-relaxed">
            Discover how our expert team transforms your ideas into powerful
            mobile applications that enhance user engagement and drive business
            growth.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-12 bg-gradient-to-r from-indigo-600 to-blue-500 text-white rounded-lg shadow-xl">
          <h2 className="text-4xl font-semibold mb-4">
            Elevate Your Business with a Mobile App
          </h2>
          <p className="text-md max-w-2xl mx-auto mb-8">
            Partner with us for tailored mobile solutions that resonate with
            your audience and achieve your business goals.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-500 font-semibold py-4 px-10 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-100"
          >
            Let’s Connect
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Custom Solutions
            </h3>
            <p className="text-gray-600">
              We create bespoke mobile apps tailored to your unique
              requirements, ensuring maximum impact and effectiveness.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Intuitive UX/UI
            </h3>
            <p className="text-gray-600">
              Our designs prioritize user experience, making apps that are not
              only functional but also delightful to use.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">
              Robust Performance
            </h3>
            <p className="text-gray-600">
              We optimize your app for speed and efficiency, ensuring smooth
              performance even during peak usage.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl font-semibold text-indigo-900 mb-8">
            Our Seamless Development Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                1. Discovery
              </h3>
              <p className="text-gray-600">
                We begin with understanding your vision and objectives to craft
                a roadmap tailored to your project.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                2. Design
              </h3>
              <p className="text-gray-600">
                Our designers create wireframes and interactive prototypes to
                visualize your app's functionality.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                3. Development
              </h3>
              <p className="text-gray-600">
                Our developers utilize the latest technologies to build a
                scalable and maintainable app.
              </p>
            </div>
            <div className="bg-indigo-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-lg font-semibold text-indigo-600 mb-2">
                4. Launch
              </h3>
              <p className="text-gray-600">
                We thoroughly test your app and assist in deploying it to reach
                your audience effectively.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl font-semibold text-indigo-900 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-3xl mx-auto">
            <blockquote className="text-md text-gray-600 italic">
              "The team at OmniserveInfotech delivered beyond our
              expectations. Our app is a hit!"
            </blockquote>
            <blockquote className="text-md text-gray-600 italic">
              "Outstanding service and a seamless process. Highly recommend for
              app development!"
            </blockquote>
            <blockquote className="text-md text-gray-600 italic">
              "Our partnership with OmniserveInfotech transformed our vision
              into a reality!"
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-12 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-md">
          <h2 className="text-4xl font-bold mb-4">
            Let’s Create the Future Together!
          </h2>
          <p className="text-md max-w-2xl mx-auto mb-8">
            Ready to develop an app that makes a difference? Contact us today to
            discuss your project and get started!
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-500 font-semibold py-4 px-10 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-100"
          >
            Reach Out
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileAppDevelopment;