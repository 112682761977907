import React, { useEffect } from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import gatewayDevicesImg from "../../images/hero-bg.webp";
import { Link } from "react-router-dom";
import { FaMobileAlt, FaDollarSign, FaSync, FaShieldAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

const GatewayDevicesPage = () => {
  useEffect(() => {
    document.title = "Omniserve Infotech - Gateway Devices";
  }, []);

  return (
    <>
      <Helmet>
        <title>OmniserveInfotech - Gateway Devices</title>
      </Helmet>
      <NavBar />

      {/* Hero Section */}
      <div className="relative w-full h-screen overflow-hidden">
        <img
          src={gatewayDevicesImg}
          alt="Gateway Devices"
          className="absolute inset-0 object-cover w-full h-full transform scale-110"
          loading="lazy"
        />
        <div className="absolute inset-0 flex items-center justify-center text-white bg-gradient-to-t from-blue-900 to-transparent">
          <div className="text-center px-4 sm:px-8 lg:px-16">
            <h1 className="text-5xl md:text-7xl font-extrabold mb-4">
              Gateway Devices
            </h1>
            <p className="text-base md:text-xl mb-8 max-w-2xl mx-auto">
              Empowering businesses with cutting-edge GSM gateway solutions that
              bridge the gap between wireless and wired networks.
            </p>
            <Link to="/contact">
              <button className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-full shadow-lg transition duration-300 button">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto p-6 mt-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Why Choose Our Gateway Devices?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <FeatureItem
            icon={<FaDollarSign className="text-blue-600 h-12 w-12" />}
            title="Cost-Effective"
            description="Reduce communication costs significantly."
          />
          <FeatureItem
            icon={<FaMobileAlt className="text-blue-600 h-12 w-12" />}
            title="Flexible Connectivity"
            description="Easily connect GSM to VoIP or PSTN."
          />
          <FeatureItem
            icon={<FaSync className="text-blue-600 h-12 w-12" />}
            title="Scalable Solutions"
            description="Adapt to the growing needs of your business."
          />
          <FeatureItem
            icon={<FaShieldAlt className="text-blue-600 h-12 w-12" />}
            title="High Reliability"
            description="Ensure consistent and reliable communications."
          />
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gray-100 py-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-6">
          What Our Clients Say
        </h2>

        <div className="flex flex-wrap justify-center space-x-4">
          <div className="max-w-xs bg-white shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105 duration-300 border-l-4 border-blue-500">
            <div className="flex items-center mb-4">
              <div className="bg-blue-500 text-white rounded-full w-12 h-12 flex items-center justify-center mr-4">
                <FaUser className="h-6 w-6" /> {/* User Icon */}
              </div>
              <h3 className="font-semibold text-lg">Ankit Gupta</h3>
            </div>
            <p className="text-gray-600 italic">
              "Using Omniserve's gateway devices has transformed our
              communication strategies!"
            </p>
          </div>

          <div className="max-w-xs bg-white shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105 duration-300 border-l-4 border-green-500">
            <div className="flex items-center mb-4">
              <div className="bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center mr-4">
                <FaUser className="h-6 w-6" /> {/* User Icon */}
              </div>
              <h3 className="font-semibold text-lg">Kriti Thakur</h3>
            </div>
            <p className="text-gray-600 italic">
              "Their solutions are reliable and cost-effective. Highly
              recommended!"
            </p>
          </div>

          <div className="max-w-xs bg-white shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105 duration-300 border-l-4 border-orange-500">
            <div className="flex items-center mb-4">
              <div className="bg-orange-500 text-white rounded-full w-12 h-12 flex items-center justify-center mr-4">
                <FaUser className="h-6 w-6" /> {/* User Icon */}
              </div>
              <h3 className="font-semibold text-lg">Meena Sarkar</h3>
            </div>
            <p className="text-gray-600 italic">
              "The service quality is excellent, and the support is
              commendable!"
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const FeatureItem = ({ icon, title, description }) => (
  <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
    <div className="mb-4">{icon}</div>
    <h3 className="font-semibold text-xl mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

export default GatewayDevicesPage;
