import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const CustomSoftwareDevelopment = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-8 lg:mt-20">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Build Tailored Solutions with Custom Software Development
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Our custom software development services provide businesses with
            unique, scalable solutions that solve complex challenges and drive
            growth.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Turn Your Vision Into Reality!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            We design and develop custom software that empowers businesses to
            thrive in today's competitive landscape.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-50"
          >
            Get Started Today
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Tailored Solutions
            </h3>
            <p className="text-gray-600">
              We develop custom software solutions designed specifically to meet
              your business goals and requirements.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Scalable Architecture
            </h3>
            <p className="text-gray-600">
              Our solutions are built to scale with your business, ensuring they
              grow and evolve with your needs.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Ongoing Support
            </h3>
            <p className="text-gray-600">
              We offer continuous support and maintenance to ensure your
              software remains efficient and up-to-date.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our Custom Software Development Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                1. Requirements Analysis
              </h3>
              <p className="text-gray-600">
                We work closely with you to understand your business objectives
                and define clear software requirements.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                2. Solution Design
              </h3>
              <p className="text-gray-600">
                Our experts design software solutions that are tailored to your
                specific needs, ensuring a perfect fit.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                3. Development & Testing
              </h3>
              <p className="text-gray-600">
                We develop and rigorously test the software to ensure it meets
                the highest quality standards.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                4. Deployment & Support
              </h3>
              <p className="text-gray-600">
                Once the software is deployed, we offer ongoing support and
                maintenance to ensure smooth operation.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Their custom software helped streamline our operations and saved
              us time and money."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "The software they developed perfectly fits our business needs."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "We saw immediate improvements in efficiency after implementing
              their solution."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-blue-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Let's Build Your Custom Software Solution!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            Our team is ready to develop custom software tailored to your
            business. Contact us to get started on your project.
          </p>
          <a
            href="/contact"
            className="inline-block bg-blue-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CustomSoftwareDevelopment;